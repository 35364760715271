import axios from "axios";
import store from "../store";
import { IAuthState } from "@monorepo/authorization/src/store/modules/auth";

axios.interceptors.request.use((req) => {
  const token = (store?.state?.auth as IAuthState)?.user?.details?.token;

  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});
