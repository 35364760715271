











import { defineComponent } from "@vue/composition-api";
import { mapGetters, mapMutations } from "vuex";
import { getSectionsName, Sections } from "@monorepo/utils/src/types/cellsSections";

type item = {
  title: string;
  section: string;
  sectionShort: string;
  href: string;
  id: string;
  item?: {
    date: string;
    createDate: string;
    number: number;
    name: string;
    id: number;
  };
};

export default defineComponent({
  name: "HistoryItems",
  props: {
    type: {
      type: String,
      default: "ead",
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    data(): { id: string; title: string; href: string }[] {
      switch (this.type) {
        case "ead":
          return [...(this.user.eadHistory || [])].reverse().map((item: item) => {
            return {
              ...item,
              title: `${getSectionsName(item.section as Sections)} - ${item.item?.number || item.item?.name || item.item?.id}`,
            };
          });
        case "inventory":
          return [...(this.user.inventoryHistory || [])].reverse().map((item: item) => {
            return {
              ...item,
              title: `${getSectionsName(item.section as Sections)} - ${item.item?.number || item.item?.name || item.item?.id}`,
            };
          });
        default:
          return [];
      }
    },
  },
  methods: {
    ...mapMutations("auth", ["deleteItemFromEadHistory", "deleteItemFromInventoryHistory"]),
    openItem(item: { id: string; href: string }) {
      switch (this.type) {
        case "ead":
          this.$router.push({ path: item.href, query: { historyEadId: item.id } });
          break;
        case "inventory":
          this.$router.push({ path: item.href, query: { historyInventoryId: item.id } });
          break;
        default:
          break;
      }
    },
    deleteItem(item: { id: string }) {
      switch (this.type) {
        case "ead":
          this.deleteItemFromEadHistory(item.id);
          break;
        case "inventory":
          this.deleteItemFromInventoryHistory(item.id);
          break;
        default:
          break;
      }
    },
  },
});
