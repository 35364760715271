import axios from "axios";
import { cancelStore } from "@monorepo/utils/src/api/cancelStore";
//import { simpleHash } from "@monorepo/utils/src/api/hash";
//import { v4 as uuid } from "uuid";

axios.interceptors.request.use((config) => {
  /* some logic */
  //const hash = simpleHash(`${config.method}${config.url}` + config.method?.toLowerCase() === "get" ? "" : uuid());
  const key = Symbol.for(`${config.method}${config.url}`);
  const cancellation = cancelStore.get(key);
  if (cancellation && config.params?.limit) {
    cancellation.cancel("Operation canceled by the user.");
  }

  const source = axios.CancelToken.source();
  cancelStore.set(key, source);

  return {
    ...config,
    hash: key,
    cancelToken: source.token,
  };
});

axios.interceptors.response.use(
  (config) => {
    cancelStore.delete((config.config as { hash: string }).hash);
    return config;
  },
  (error) => {
    if (error?.config?.hash) {
      cancelStore.delete(error.config.hash);
    }
    return Promise.reject(error);
  }
);
