import { defineAsyncComponent } from "@vue/composition-api";
import { viewUniqKey as SystemAccessesViewUniqKey } from "@monorepo/utils/src/variables/projectsData/systemAccesses/viewTitle";
import { viewUniqKey as SettingsViewUniqKey } from "@monorepo/utils/src/variables/projectsData/settingsView/viewTitle";
import { viewUniqKey as FilestorageViewUniqKey } from "@monorepo/utils/src/variables/projectsData/filestorage/viewTitle";
import { viewUniqKey as InformationSecurityJournalViewUniqKey } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/viewTitle";

const SettingsView = defineAsyncComponent(
  () => import(/* webpackChunkName: "informationSecuritySettingsView" */ "@monorepo/informationSecurity/src/views/Settings/SettingsView.vue")
);
const FilestorageView = defineAsyncComponent(
  () => import(/* webpackChunkName: "informationSecuritySettingsView" */ "@monorepo/informationSecurity/src/views/Filestorage/FilestorageView.vue")
);
const SystemAccessesView = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "informationSecuritySystemAccesses" */ "@monorepo/informationSecurity/src/views/SystemAccesses/SystemAccessesView.vue"
    )
);
const InformationSecurityJournalView = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "InformationSecurityJournalView" */ "@monorepo/informationSecurity/src/views/InformationSecurityJournal/InformationSecurityJournalView.vue"
    )
);

const ExternalAPIUsersView = defineAsyncComponent(
  () => import(/* webpackChunkName: "ExternalAPIUsersView" */ "@monorepo/informationSecurity/src/views/ExternalAPIUsers/ExternalAPIUsers.vue")
);

export const commonRoutes = [
  {
    path: "/administration/filestorage",
    component: FilestorageView,
    meta: { requiresAuth: true, key: FilestorageViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/administration/settings",
    component: SettingsView,
    meta: { requiresAuth: true, key: SettingsViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/information-security/system-access/:tab",
    component: SystemAccessesView,
    name: "systemAccesses",
    props: true,
    meta: { requiresAuth: true, key: SystemAccessesViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/information-security/information-security-log",
    component: InformationSecurityJournalView,
    name: "InformationSecurityJournal",
    meta: { requiresAuth: true, key: InformationSecurityJournalViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/information-security/external-api-users",
    component: ExternalAPIUsersView,
    name: "ExternalAPIUsersView",
    meta: { requiresAuth: true, key: InformationSecurityJournalViewUniqKey, isNeedNavigationPanel: true },
  },
];
