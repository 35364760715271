















import { defineComponent } from "@vue/composition-api";
import { mapActions } from "vuex";

enum Types {
  ERROR = "EMERGENCY",
  WARN = "MAINTENANCE",
}

export default defineComponent({
  name: "ServiceTypeNotification",
  data() {
    return {
      type: Types.ERROR,
      Types,
      isShow: false,
    };
  },
  computed: {
    message(): string {
      switch (this.type) {
        case Types.WARN:
          return "Сервисный режим работы системы. Часть функционала может быть недоступна";
        case Types.ERROR:
          return "Аварийный режим работы системы. Часть функционала может быть недоступна";
        default:
          return "";
      }
    },
  },
  methods: {
    ...mapActions("app", ["getNotificationSettings"]),
    async init() {
      const status: Types = await this.getNotificationSettings("SYSTEM_STATUS");
      this.type = status;
      this.isShow = [Types.WARN, Types.ERROR].includes(status);
      this.$emit("toggleShowServiceType", this.isShow);
    },
  },
  mounted() {
    this.init();
  },
});
