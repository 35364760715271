




import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "SettingItemWrap",
  props: {
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
});
