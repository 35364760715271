import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@monorepo/utils/src/plugins/vuetify";
import "@monorepo/utils/src/plugins/vueFormulate";
import "@monorepo/utils/src/api/cacelSameQuery";
import "@monorepo/app/src/utils/addAxiosAuthHeader";
import "@monorepo/app/src/utils/redirectToLogin";
import VueCompositionAPI from "@vue/composition-api";

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
