import { defineAsyncComponent } from "@vue/composition-api";
import { viewUniqKey as eventLogViewViewUniqKey } from "@monorepo/utils/src/variables/projectsData/eventLog/viewTitle";
import { viewTitle as systemNotificationViewUniqKey } from "@monorepo/utils/src/variables/projectsData/systemNotificationsList/viewTitle";
import { viewUniqKey as MonitoringBussinessProccesesViewUniqKey } from "@monorepo/utils/src/variables/projectsData/MonitoringBussinessProccesesView/viewTitle";
import { viewUniqKey as TKLogViewUniqKey } from "@monorepo/utils/src/variables/projectsData/TKLogView/viewTitle";
import { viewUniqKey as bugReportViewUniqKey } from "@monorepo/utils/src/variables/projectsData/bugReportView/viewTitle";

const TableView = defineAsyncComponent(
  () => import(/* webpackChunkName: "monitoringTableView" */ "@monorepo/monitoring/src/views/EventLogView/EventLogView.vue")
);

const MonitoringBussinessProccesesView = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "MonitoringBussinessProcceses" */ "@monorepo/monitoring/src/views/MonitoringBussinessProcceses/MonitoringBussinessProcceses.vue"
    )
);

const TKLogView = defineAsyncComponent(
  () => import(/* webpackChunkName: "informationSecurityTKLogView" */ "@monorepo/monitoring/src/views/TKLogView/TKLogView.vue")
);

const SystemNotifications = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "systemNotificationListView"*/ "@monorepo/monitoring/src/views/SystemNotificationsList/SystemNotificationsListView.vue"
    )
);

const SyncLogOik = defineAsyncComponent(
  () => import(/* webpackChunkName: "systemNotificationListView"*/ "@monorepo/monitoring/src/views/SyncLogOik/SyncLogOikView.vue")
);

const BugReportListView = defineAsyncComponent(
  () => import(/* webpackChunkName: "bugReportListView"*/ "@monorepo/monitoring/src/views/BugReportListView/BugReportListView.vue")
);

export const commonRoutes = [
  {
    path: "/monitoring/monitoring",
    component: MonitoringBussinessProccesesView,
    meta: { requiresAuth: true, key: MonitoringBussinessProccesesViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/monitoring/event-log",
    component: TableView,
    meta: { requiresAuth: true, key: eventLogViewViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/administration/system-notifications",
    component: SystemNotifications,
    meta: { requiresAuth: true, key: systemNotificationViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/monitoring/tk_processing_log",
    component: TKLogView,
    meta: { requiresAuth: true, key: TKLogViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/monitoring/sync-log-oik/:tab",
    component: SyncLogOik,
    meta: { requiresAuth: true, key: TKLogViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/monitoring/sync-log-oik",
    component: SyncLogOik,
    meta: { requiresAuth: true, key: TKLogViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/administration/bug-reports",
    component: BugReportListView,
    meta: { requiresAuth: true, key: bugReportViewUniqKey, isNeedNavigationPanel: true },
  },
];
