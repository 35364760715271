import jwt_decode from "jwt-decode";
import { Store } from "vuex";
import VueRouter from "vue-router";
import { IAuthState } from "@monorepo/authorization/src/store/modules/auth";
import { navigationItems } from "@monorepo/app/src/constants/navigationItems";
import { authorities } from "@monorepo/utils/src/authorities/authorities";

const navigationItemsPaths = navigationItems
  .reduce((result: { href?: string; rights?: string[] }[], item) => [...result, ...(item.subNav || [])], [])
  .reduce((result: Record<string, string[]>, item: { href?: string; rights?: string[] }) => {
    result = {
      ...result,
      [item.href || ""]: item.rights || [],
    };
    return result;
  }, {});

const objectRulesPaths: Record<string, string[]> = {
  ...navigationItemsPaths,
  "/information-security/system-access/users": [authorities.ROLE_LIST, authorities.USER_LIST],
  "/monitoring/sync-log-oik/journalTab": [authorities.OIK_LOG_LIST],
};

const hasRules = (objectRulesPaths: string[], authorities: Record<string, boolean>) =>
  (objectRulesPaths || []).some((rule: string) => authorities?.[rule]);

export const addRouteGuards = (router: VueRouter, store: Store<Record<string, unknown>>): void => {
  // redirect if auth token invalid
  router.beforeEach(async (to, from, next) => {
    try {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        const token = store.getters["auth/user"]?.details?.token || "";
        const decoded: { exp: number } = jwt_decode(token) || {};
        if (!decoded) {
          await store.dispatch("logout");
          next("/login");
          return;
        }
        const expDate = decoded?.exp;
        if (expDate * 1000 <= Date.now()) {
          try {
            await store.dispatch("auth/refreshToken");
            next();
          } catch (e: unknown) {
            await store.dispatch("logout");
            next("/login");
          }
          return;
        }
        next();
      } else {
        next();
      }
    } catch (e) {
      await store.dispatch("logout");
      next("/login");
    }
  });

  // redirect if auth required route opened by guest user
  router.beforeEach((to, from, next) => {
    const authorities = store.getters["auth/user"]?.authorities.reduce((result: { [key: string]: boolean }, item: { authority: string }) => {
      result[item.authority || ""] = true;
      return result;
    }, {});
    if (objectRulesPaths[to.path]?.length) {
      const isHasRule = hasRules(objectRulesPaths[to.path], authorities);

      if (!isHasRule) {
        next("/");
        return;
      }
    }
    next();
  });

  // redirect if auth required route opened by guest user
  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if ((store.state.auth as IAuthState)?.isAuth) {
        next();
        return;
      }
      if (to?.query?.card) {
        localStorage.setItem("link", to.fullPath as string);
      }
      next("/login");
    } else {
      next();
    }
  });

  // redirect if guest route opened by auth user
  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest && !record.meta.alwaysOpen)) {
      if ((store.state.auth as IAuthState)?.isAuth) {
        next("/");
        return;
      }
      next();
    } else {
      next();
    }
  });
};
