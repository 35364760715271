import { defineAsyncComponent } from "@vue/composition-api";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/viewTitle";

const ERCProtocolsView = defineAsyncComponent(
  () => import(/* webpackChunkName: "ERCProtocolsView" */ "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/ERCProtocolsView.vue")
);

export const commonRoutes = [
  {
    path: "/erc/inventory",
    component: ERCProtocolsView,
    meta: { requiresAuth: true, key: viewUniqKey, isNeedNavigationPanel: true },
  },
];
