






import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { IActionType } from "@monorepo/utils/src/eventBus/types/action";
import { IModalEventPayload } from "@monorepo/utils/src/eventBus/types/modalPayload";

export default defineComponent({
  name: "Modal",
  components: {
    ModalUnderConstruction: () =>
      import(
        /* webpackChunkName: "modal.MonitoringSettingsEventTableModal" */
        "./ModalUnderConstruction.vue"
      ),
    WarningModal: () =>
      import(
        /* webpackChunkName: "modal.WarningModal" */
        "./WarningModal.vue"
      ),
    ChangeStatusModal: () =>
      import(
        /* webpackChunkName: "modal.ChangeStatusModal" */
        "./ChangeStatusModal.vue"
      ),
    BlockUserModal: () =>
      import(
        /* webpackChunkName: "modal.SystemAccessesBlockUserModal" */
        "@monorepo/informationSecurity/src/views/SystemAccesses/components/users/BlockUserModal.vue"
      ),
    UnblockUserModal: () =>
      import(
        /* webpackChunkName: "modal.SystemAccessesUnblockUserModal" */
        "@monorepo/informationSecurity/src/views/SystemAccesses/components/users/UnblockUserModal.vue"
      ),
    SetPeriodModal: () =>
      import(
        /* webpackChunkName: "modal.HandoverSetPeriodModal" */
        "@monorepo/inventory/src/views/HandoverView/components/SetPeriodModal.vue"
      ),
    GenerateTkModal: () =>
      import(
        /* webpackChunkName: "modal.GenerateTkModal" */
        "@monorepo/administration/src/views/TkGeneration/components/GenerateTkModal.vue"
      ),
    AddApproveModal: () =>
      import(
        /* webpackChunkName: "modal.ERCProtocolsAddApproveModal" */
        "@monorepo/inventory/src/views/ERCProtocolsView/components/AddApproveModal.vue"
      ),
    CommisionUserModal: () =>
      import(
        /* webpackChunkName: "modal.ERCProtocolsCommisionUserModal" */
        "@monorepo/expertReviewCommission/src/views/ERCProtocolsView/components/CommisionUserModal.vue"
      ),
    ForbiddenDeleteRoleModal: () =>
      import(
        /* webpackChunkName: "modal.SystemAccessesForbiddenDeleteRoleModal" */
        "@monorepo/informationSecurity/src/views/SystemAccesses/components/roles/ForbiddenDeleteRoleModal.vue"
      ),
    ConcatFundModal: () =>
      import(
        /* webpackChunkName: "modal.ConcatArchiveModal" */
        "@monorepo/catalog/src/views/FundView/components/ConcatFundModal.vue"
      ),
    DeleteModal: () =>
      import(
        /* webpackChunkName: "modal.DeleteModal" */
        "./DeleteModal.vue"
      ),
    EducationPanelModal: () =>
      import(
        /* webpackChunkName: "modal.EducationPanel" */
        "./EducationPanel.vue"
      ),
    BugReportModal: () =>
      import(
        /* webpackChunkName: "modal.BugReportModal" */
        "./BugReport.vue"
      ),
    NotificationModal: () =>
      import(
        /* webpackChunkName: "modal.NotificationModal" */
        "@monorepo/app/src/components/NotificationModal.vue"
      ),
  },
  data() {
    return {
      isOpenModal: false,
      currentModalComponent: "",
      currentModalProperties: {},
      isHideOverlay: false,
    };
  },
  computed: {
    isOpenModalModel: {
      get(): boolean {
        return this.isOpenModal;
      },
      set(value: boolean) {
        this.isOpenModal = value;
      },
    },
  },
  methods: {
    toggleModal(action: IActionType<IModalEventPayload>) {
      this.isOpenModal = action.payload.isOpen;
      this.currentModalComponent = action.payload.name ?? "";
      this.currentModalProperties = action.payload.props ?? {};
      if (action.payload.props?.isHideOverlay) {
        this.isHideOverlay = action.payload.props.isHideOverlay as boolean;
      }
      if (!this.isOpenModal) {
        this.isHideOverlay = false;
      }
    },
  },
  beforeMount() {
    eventBus.$on(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, this.toggleModal);
  },
  beforeDestroy() {
    eventBus.$off(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, this.toggleModal);
  },
});
