















import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { NotifyPriorities } from "@monorepo/app/src/types/NotifyPriorities";

export default defineComponent({
  name: "Notification",
  computed: {
    description(): string {
      const strings = this.notification?.description?.split("\n");
      const strCountSymbol = 50;
      if (strings.length) {
        if (strings.length === 1) {
          return strings.map((item: string) => {
            return item.length > 150 ? item.slice(0, 150) + "..." : item;
          });
        }
        if (strings.length > 3) {
          return strings
            .map((item: string) => {
              return item.length > strCountSymbol ? item.slice(0, strCountSymbol) + "..." : item;
            })
            .slice(0, 3);
        }
        return strings.map((item: string, index: number) => {
          if (index === strings.length - 1) {
            return strings.length === 2
              ? item.length > 100
                ? item.slice(0, 97) + "..."
                : item
              : item.length > 50
              ? item.slice(0, strCountSymbol) + "..."
              : item;
          }
          return item.length > strCountSymbol ? item.slice(0, strCountSymbol) + "..." : item;
        });
      }
      return this.notification.description;
    },
  },
  data() {
    return {
      NotifyPriorities,
    };
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    isNeedHide: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.subscribeToHideNotification();
  },
  methods: {
    subscribeToHideNotification() {
      if (this.isNeedHide && this.notification?.priority !== NotifyPriorities.HIGH) {
        setTimeout(this.hideNotification, 3000);
      }
    },
    hideNotification() {
      this.$emit("delete", this.notification.guid);
    },
    openModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
  },
});
