import VueRouter, { RawLocation, Route } from "vue-router";

export const customRouterPush = (): void => {
  const originalPush = VueRouter.prototype.push;
  VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
    return new Promise((resolve, reject) => {
      originalPush.call(
        this,
        location,
        () => {
          resolve(this.currentRoute);
        },
        (error) => {
          if (error.name === "NavigationDuplicated") {
            resolve(this.currentRoute);
          } else {
            reject(error);
          }
        }
      );
    });
  };
};
