import Vue from "vue";
import Vuetify from "vuetify/lib";
import ru from "vuetify/src/locale/ru";
import "@mdi/font/css/materialdesignicons.css";
import "../fonts/Golos/index.sass";
import "./index.sass";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        black: "#1A1A1A",
        fontBlack: "#21211f",
        primary: "#2462D1",
        gray: "#f5f5f7",
        gray2: "#D9D9DE",
        gray3: "#A7A7AB",
        blue: "#2462D1",
        blue1: "#0C3276",
        red1: "#D34039",
      },
    },
  },
  iconfont: "mdi",
  lang: {
    locales: { ru },
    current: "ru",
  },
};

const vuetify = new Vuetify(opts);

export default vuetify;
