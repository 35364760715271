import { ComponentInstance, onUnmounted } from "@vue/composition-api";
import SockJS from "sockjs-client";
import { Client, CompatClient, IStompSocket } from "@stomp/stompjs";
import { IAuthState } from "@monorepo/authorization/src/store/modules/auth";

const useSocket = (socketPath: string, root: ComponentInstance): Promise<{ socket: WebSocket; stompClient: CompatClient }> => {
  console.log("Starting connection to WebSocket Server");
  const token = (root.$store?.state?.auth as IAuthState)?.user?.details?.token;
  const login = (root.$store?.state?.auth as IAuthState)?.user?.details?.username || "";

  if (!token) {
    console.error("Close connection to WebSocket Server, no token");
  }
  const socket = new SockJS(socketPath);
  // const stompClient = Stomp.over(socket);
  const stompClient = new Client({
    brokerURL: "",
    connectHeaders: {
      Authorization: `Bearer ${token}`,
      login,
    },
    debug: function (str) {
      console.log(str);
    },
    reconnectDelay: 5000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
  });

  stompClient.webSocketFactory = function (): IStompSocket {
    // Note that the URL is different from the WebSocket URL
    return socket as IStompSocket;
  };

  onUnmounted(() => {
    if (stompClient.connected) {
      socket.close();
      stompClient.deactivate();
    }
  });

  return new Promise((resolve, reject) => {
    stompClient.onConnect = (event: unknown) => {
      console.log("Successfully connected to the echo websocket server...", event);
      resolve({ stompClient: stompClient as CompatClient, socket });
    };

    stompClient.onStompError = (event: unknown) => {
      console.error("error in socket: ", event);
      reject(event);
    };

    stompClient.onDisconnect = (event: unknown) => {
      console.log("Successfully closed connected to the echo websocket server...", event);
    };
    stompClient.activate();
    // stompClient.connect({}, connectCallback, errorCallback, closeEventCallback);
  });
};

export default useSocket;

// import { onUnmounted, ComponentInstance } from "@vue/composition-api";
// import SockJS from "sockjs-client";
// import { Stomp } from "@stomp/stompjs";
//
// const useSocket = (socketPath: string, root: ComponentInstance): Promise<any> => {
//   console.log("Starting connection to WebSocket Server");
//   // const token = (root.$store?.state?.auth as IAuthState)?.user?.details?.token;
//   // if (!token) {
//   //   console.error("Close connection to WebSocket Server, no token");
//   // }
//   const socket = new SockJS(socketPath);
//   const stompClient = Stomp.over(socket);
//
//   onUnmounted(() => {
//     if (stompClient.connected) {
//       socket.close();
//       stompClient.disconnect();
//     }
//   });
//
//   return new Promise((resolve, reject) => {
//     const connectCallback = (event: unknown) => {
//       console.log("Successfully connected to the echo websocket server...", event);
//       resolve(stompClient);
//     };
//
//     const errorCallback = (event: unknown) => {
//       console.error("error in socket: ", event);
//       reject(event);
//     };
//
//     const closeEventCallback = (event: unknown) => {
//       console.log("Successfully closed connected to the echo websocket server...", event);
//     };
//
//     stompClient.connect(
//       {
//         // Authorization: `Bearer ${token}`,
//         // abc: "abc",
//       },
//       connectCallback,
//       errorCallback,
//       closeEventCallback
//     );
//   });
// };
//
// export default useSocket;
