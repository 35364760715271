




import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TmpBlock",
  props: {
    height: {
      type: String,
    },
    width: {
      type: String,
    },
  },
});
