import { isEmpty } from "lodash";
import axios, { AxiosResponse } from "axios";
import { IResponse } from "@monorepo/utils/src/api/types/response";
import { paramsSerializer } from "@monorepo/utils/src/api/paramsSerializer";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";

export const getFullPath = (path: string, ...additional: string[]): string => {
  return `${path}${(additional || []).join("")}`;
};

export const encodeQueryData = (data: Partial<IConvertFiltersToApiResponse> | { ids: string[] }): string => {
  try {
    const ret = [];
    for (const d in data)
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(JSON.stringify(data[d as keyof (IConvertFiltersToApiResponse | { ids: string[] })])));
    return "?" + ret.join("&");
  } catch (e) {
    return "";
  }
};

export const checkExistLibrary = (data: unknown, cb: () => void): void => {
  if (isEmpty(data)) {
    cb();
  }
};

type Error = { noBaseError?: boolean; __CANCEL__?: boolean };

// Doesn't matter return type - common function
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getQuery = async <T>(
  path: string,
  params?: unknown,
  isNeedSerializer = true,
  errorMessage?: string,
  dataField?: string
): Promise<Record<string, any>> => {
  try {
    const { data: response }: AxiosResponse<IResponse<T>> = await axios.get(getFullPath(path), {
      params,
      paramsSerializer: isNeedSerializer ? paramsSerializer : undefined,
    });

    const { error, message, code, data, ...additional } = dataField ? (response?.[dataField] as IResponse<T>) : response;
    if (error || code !== 0) {
      showNotification(errorMessage || `Ошибка ответа от сервера: ${message}`);
      return { error, message, code, data: null };
    }
    return { data, meta: response?.meta, code, ...additional };
  } catch (e: unknown) {
    if (!(e as unknown as Error)?.noBaseError && !(e as unknown as Error)?.__CANCEL__) {
      console.error(e);
      showNotification(errorMessage || "Ошибка соединения с сервером.");
    }
    return { data: null, error: e };
  }
};

export const validateEmail = (email: string): RegExpMatchArray | null => {
  return email.match(
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const validateCron = (cron: string): RegExpMatchArray | null => {
  return cron.match(
    // eslint-disable-next-line
    /^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC) (\*|([0-7])|\*\/([0-7])|SUN|MON|TUE|WED|THU|FRI|SAT)$/
  );
};
