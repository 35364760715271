
























import { defineComponent } from "@vue/composition-api";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import NotificationsTrigger from "./NotificationsTrigger.vue";
import HistoryItems from "./HistoryItems.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";

export default defineComponent({
  name: "Header",
  components: {
    NotificationsTrigger,
    HistoryItems,
  },
  computed: {
    ...mapGetters("app", ["isPashalka"]),
    imgPath(): string {
      return `${environmentVariables.BASE_URL}assets/images/navigation/logo.svg`;
    },
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapMutations("app", ["setIsOpenProfile"]),
    icon(name: string) {
      return `${environmentVariables.BASE_URL}assets/images/navigation/${name}.svg`;
    },
    goToArchiveBaseUrl() {
      window.open("https://archives.gov.ru/", "_blank");
    },
    goToMainPage() {
      this.$router.push("/");
    },
    goToBaseUrl() {
      window.open("https://digital.gov.ru/", "_blank");
    },
    openModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    resetPassword() {
      this.$router.push("/reset-password");
    },
    async logoutCb() {
      await this.logout();
      await this.$router.push("/login");
    },
    openProfile() {
      this.setIsOpenProfile(true);
    },
  },
});
