import { defineAsyncComponent } from "@vue/composition-api";

const LoginView = defineAsyncComponent(() => import(/* webpackChunkName: "login" */ "../views/Login/LoginView.vue"));
const ResetPasswordView = defineAsyncComponent(() => import(/* webpackChunkName: "login" */ "../views/ResetPassword/ResetPasswordView.vue"));

export const commonRoutes = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { guest: true },
  },
  {
    path: "/reset-password/:hash",
    name: "ResetPassword",
    component: ResetPasswordView,
    meta: { guest: true, alwaysOpen: true },
  },
  {
    path: "/reset-password",
    name: "ResetPassword1",
    component: ResetPasswordView,
    meta: { guest: true, alwaysOpen: true },
  },
];
