









import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { NOTIFICATION } from "@monorepo/utils/src/eventBus/events/notification";
import { IActionType } from "@monorepo/utils/src/eventBus/types/action";
import { INotification, NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

interface IData {
  isOpen: boolean;
  message: string;
  type: null | string;
}

export default defineComponent({
  name: "Snackbar",
  data(): IData {
    return {
      isOpen: false,
      message: "",
      type: null,
    };
  },
  mounted() {
    eventBus.$on(NOTIFICATION.SHOW_NOTIFICATION, this.openNotification);
  },
  beforeDestroy() {
    eventBus.$off(NOTIFICATION.SHOW_NOTIFICATION, this.openNotification);
  },
  methods: {
    closeMessage() {
      this.isOpen = false;
      this.message = "";
      this.type = null;
    },
    openNotification(packet: IActionType<INotification>) {
      switch (packet.payload.status) {
        case NOTIFICATION_STATUS.ERROR:
          this.type = "error";
          break;
        case NOTIFICATION_STATUS.SUCCESS:
          this.type = "success";
          break;
        case NOTIFICATION_STATUS.INFO:
          this.type = "primary";
          break;
        default:
          console.warn("Не добавлен тип сообщения");
          this.type = null;
          break;
      }
      this.message = packet.payload.message;
      this.isOpen = true;
    },
  },
});
