import { Module } from "vuex";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { IRootState } from "@monorepo/app/src/store";
import { getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { AuthResponse, IAuthResponseData } from "@monorepo/authorization/src/views/Login/types/authResponse";
import { IUserHistory } from "@monorepo/utils/src/types/IUserHistory";
import { IVuexContext } from "@monorepo/utils/src/store/modules/actions";
import { convertItemFromStringToObj } from "@monorepo/utils/src/api/convertHistoryItemToApi";

export interface IAuthState {
  isAuth: boolean;
  isTwoStepsAuth: boolean;
  otpAuthSessionGuid: string | null;
  secondStepObj: {
    username: string;
    password: string;
    fingerprint: string;
  };
  user: AuthResponse | null;
  cardMode: { [key: string]: CardMode };
  isOpenEducation: boolean;
  isCardModeLoaded: boolean;
  cardModesDefault: { id: number; userId: number; section: string; value: string }[];
  historyItem: IUserHistory | null;
}

const getDefaultState = (): IAuthState => ({
  isAuth: false,
  isTwoStepsAuth: false,
  otpAuthSessionGuid: null,
  secondStepObj: {
    username: "",
    password: "",
    fingerprint: "",
  },
  user: null,
  isOpenEducation: false,
  historyItem: null,
  cardMode: {},
  cardModesDefault: [],
  isCardModeLoaded: false,
});

export const auth: Module<IAuthState, IRootState> = {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    setIsOpenEducation(state: IAuthState, payload: boolean) {
      state.isOpenEducation = payload;
    },
    setIsAuth(state: IAuthState, payload: boolean) {
      state.isAuth = payload;
    },
    setIsTwoStepsAuth(state: IAuthState, payload: boolean) {
      state.isTwoStepsAuth = payload;
    },
    setOtpAuthSessionGuid(state: IAuthState, payload: string) {
      state.otpAuthSessionGuid = payload;
    },
    setSecondStepObj(state: IAuthState, payload: { username: string; password: string; fingerprint: string }) {
      state.secondStepObj = payload;
    },
    setCardMode(state: IAuthState, payload: { [key: string]: CardMode }) {
      state.cardMode = { ...state.cardMode, ...payload };
    },
    setIsCardModeLoaded(state: IAuthState, payload: boolean) {
      state.isCardModeLoaded = payload;
    },
    setUserSetting(state: IAuthState, payload: Record<string, string>) {
      if (state.user) {
        state.user = {
          ...state.user,
          settings: payload,
        };
      }
    },
    setUser(state: IAuthState, payload: AuthResponse) {
      state.user = payload;
    },
    setCardModesDefault(state: IAuthState, payload: { id: number; userId: number; section: string; value: string }[]) {
      state.cardModesDefault = payload;
    },
    setUserHistory(state: IAuthState, payload: IUserHistory[]) {
      if (state.user) {
        state.user = {
          ...state.user,
          history: payload,
        };
      }
    },
    setHistoryItem(state: IAuthState, payload: IUserHistory) {
      if (state.user) {
        state.historyItem = payload;
      }
    },
    setUserEadHistory(state: IAuthState, payload: IUserHistory) {
      if (state.user) {
        state.user.eadHistory = (state.user.eadHistory ?? []).slice(0, 3).concat(payload);
      }
    },
    setUserInventoryHistory(state: IAuthState, payload: IUserHistory) {
      if (state.user) {
        state.user.inventoryHistory = (state.user.inventoryHistory ?? []).slice(0, 3).concat(payload);
      }
    },
    deleteItemFromInventoryHistory(state: IAuthState, payload: string) {
      if (state.user) {
        state.user.inventoryHistory = (state.user.inventoryHistory ?? []).filter((item) => item.id.toString() !== payload);
      }
    },
    deleteItemFromEadHistory(state: IAuthState, payload: string) {
      if (state.user) {
        state.user.eadHistory = (state.user.eadHistory ?? []).filter((item) => item.id.toString() !== payload);
      }
    },
    resetState(state: IAuthState) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    async refreshToken({ state, commit }) {
      const refreshToken = state?.user?.details?.refreshToken || "";
      const fingerprint = state?.user?.details?.fingerprint || "";
      if (!refreshToken) {
        return Promise.reject();
      }
      const response = await axios.post(getFullPath(QUERY_PATH.AUTHENTICATION_REFRESH_TOKEN), { refreshToken, fingerprint });
      const data = response.data;
      if (data?.data?.accessToken?.authenticated) {
        const user = { ...data.data.accessToken };
        user.details.fingerprint = state.user?.details.fingerprint;
        user.details.refreshToken = data.data.refreshToken;
        commit("setIsAuth", true);
        user.history = state.user?.history;
        commit("setUser", user);
        return user.details.token;
      } else {
        return Promise.reject();
      }
    },
    async getCommonUserSettingsValues(info) {
      try {
        const { data } = await axios.get(`${QUERY_PATH.GET_AVAILABLE_USER_SETTINGS_VALUES}/${info.state.user?.details?.userId}`);
        const values = (data.data || []).reduce((res: Record<string, string>, item: { setting: { code: string; name: string }; value: string }) => {
          res[item.setting.code || item.setting.name] = item.value;
          return res;
        }, {});
        info.commit("setUserSetting", values);
        return data?.data;
      } catch (e) {
        info.commit("setUserSetting", {});
      }
    },
    async auth(context, obj: { username: string; password: string; authCode?: string; isSecondStepAuth?: boolean }) {
      let data: IAuthResponseData | null;
      const path = getFullPath(obj.isSecondStepAuth ? QUERY_PATH.AUTHENTICATION_LOGIN_SECOND_STEP : QUERY_PATH.AUTHENTICATION_LOGIN);
      const authObj = await context.dispatch("getAuthRequestObj", obj);
      try {
        const response = await axios.post(path, { ...authObj });
        data = response.data;
      } catch (e) {
        return { isLogin: false, errorCode: e?.response?.data?.code };
      }

      if (data?.data?.accessToken?.authenticated) {
        await context.dispatch("completeAuth", { data, authObj });
        context.commit("setIsTwoStepsAuth", false);
        context.commit("setOtpAuthSessionGuid", "");
        return { isLogin: true };
      } else if (data?.data?.enable2FA) {
        context.commit("setIsTwoStepsAuth", true);
        context.commit("setOtpAuthSessionGuid", data?.data?.otpAuthSessionGuid);
        return { isLogin: true };
      }

      return { isLogin: false };
    },
    getAuthRequestObj(
      context,
      obj: { username: string; password: string; authCode?: string; isSecondStepAuth?: boolean }
    ): {
      username: string;
      password: string;
      fingerprint: string;
      otpAuthSessionGuid?: string | null;
      otp?: string;
    } {
      if (obj.isSecondStepAuth) {
        return { ...context.state.secondStepObj, otpAuthSessionGuid: context.state.otpAuthSessionGuid, otp: obj.authCode };
      } else {
        const fingerprint = uuid();
        const authObj = { ...obj, fingerprint };
        context.commit("setSecondStepObj", { ...authObj });
        return { ...authObj };
      }
    },
    completeAuth(context, { data, authObj }) {
      const user = { ...data.data.accessToken };
      user.details.fingerprint = authObj.fingerprint;
      user.details.refreshToken = data.data.refreshToken;
      context.commit("setIsAuth", true);
      context.commit("setUser", user);
    },
    async recreateOtpCode(context) {
      try {
        const { data } = await axios.post(QUERY_PATH.AUTHENTICATION_RECREATE_OTP, { username: context.state.secondStepObj.username });
        if (data) {
          context.commit("setOtpAuthSessionGuid", data);
        }
        return true;
      } catch (e) {
        return false;
      }
    },
    async getOtpExpirationTime() {
      try {
        const { data } = await getQuery<{ code: string; data: string | number }>(QUERY_PATH.GET_OTP_EXPIRATION_TIME);
        return data;
      } catch (e) {
        return false;
      }
    },
    async getUserHistory({ commit, state }: IVuexContext) {
      const { data, total: totalLength } = await getQuery<IUserHistory[]>(`${QUERY_PATH.GET_BROSING_HISTORY}`, {
        filters: [{ key: "userId", value: [state?.user?.details?.userId] }],
        sort: [{ field: "createDate", direction: "DESC" }],
        limit: 50,
        offset: 0,
      });
      if (data !== null) {
        commit(
          "setUserHistory",
          data.map((historyItem: IUserHistory) => {
            return {
              ...historyItem,
              filters: historyItem.filters ? convertItemFromStringToObj(historyItem.filters as string) : {},
              item: historyItem.item ? convertItemFromStringToObj(historyItem.item as string) : {},
            };
          })
        );
        return { data: state?.user?.history || [], totalLength: totalLength || 0 };
      }
      return { data: null };
    },
    async getUserHistoryById({ state, commit }, payload: string) {
      const { data } = await getQuery<IUserHistory>(`${QUERY_PATH.GET_BROSING_HISTORY}/${payload}`);
      if (data !== null) {
        commit("setHistoryItem", {
          ...data,
          filters: data.filters ? convertItemFromStringToObj(data.filters) : {},
          item: data.item ? convertItemFromStringToObj(data.item) : {},
        });
      }
      return state?.historyItem || {};
    },
    async saveUserHistory({ state, dispatch }: IVuexContext, payload: IUserHistory) {
      try {
        await axios.post(QUERY_PATH.GET_BROSING_HISTORY, {
          ...payload,
          userId: state?.user?.details?.userId,
        });
        await dispatch("getUserHistory");
      } catch (e) {
        console.error(e);
      }
    },
    async loadCardModes({ commit, state }: IVuexContext) {
      try {
        const { data } = await axios.get(`${QUERY_PATH.GET_CARD_MODES}/${state?.user?.details?.userId}`);
        commit(
          "setCardMode",
          (data.data || []).reduce((result: { [key: string]: CardMode }, item: { section: string; value: CardMode }) => {
            result[item.section] = item.value;
            return result;
          }, {})
        );
        commit("setCardModesDefault", data.data || []);
        commit("setIsCardModeLoaded", true);
      } catch (e) {
        console.error(e);
      }
    },
    async setCardMode({ commit, state }: IVuexContext, payload: { [key: string]: CardMode }) {
      try {
        const section = Object.keys(payload)[0];
        let item = state.cardModesDefault.find((item: { section: string }) => item.section === section) || {
          userId: state?.user?.details?.userId,
          section: section,
          value: "DEFAULT",
        };
        item = item.id ? { id: item.id, value: item.value } : item;
        item.value = payload[section];
        const { data } = await axios[!item.id ? "post" : "put"](`${QUERY_PATH.GET_CARD_MODES}`, item);

        commit("setCardMode", payload);
        if (!item.id) {
          commit("setCardModesDefault", (state.cardModesDefault || []).concat(data.data));
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  getters: {
    isOpenEducation(state: IAuthState) {
      return state.isOpenEducation;
    },
    isAuth(state: IAuthState) {
      return state.isAuth;
    },
    isTwoStepsAuth(state: IAuthState) {
      return state.isTwoStepsAuth;
    },
    otpAuthSessionGuid(state: IAuthState) {
      return state.otpAuthSessionGuid;
    },
    cardModeList(state: IAuthState) {
      return state.cardMode;
    },
    user(state: IAuthState) {
      return state.user;
    },
    userSettings(state: IAuthState) {
      return state.user?.settings ?? {};
    },
    isCardModeLoaded(state: IAuthState) {
      return state.isCardModeLoaded ?? {};
    },
    historyItem(state: IAuthState) {
      return state.historyItem;
    },
    isShowAnimation(state: IAuthState) {
      return state.user?.settings ? state.user?.settings["ANIMATION"] || false : false;
    },
  },
};
