



































import { defineComponent } from "@vue/composition-api";
import { mapGetters } from "vuex";
import { getSectionsName, Sections } from "@monorepo/utils/src/types/cellsSections";
import moment from "moment";

type item = {
  title: string;
  section: string;
  sectionShort: string;
  href: string;
  id: string;
  item?: {
    date: string;
    createDate: string;
    number: number;
    name: string;
    id: number;
  };
};

export default defineComponent({
  name: "FastHistoryItems",
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    historyLength(): number {
      return (this.user?.history || []).length;
    },
    items(): item[] {
      return [...(this.user?.history || [])].slice(0, this.isOpen ? 5 : this.historyLength === 2 ? 2 : 1).map((item: item) => {
        let additionalInfo = "";
        switch (item.section) {
          case Sections.EAD:
            {
              const date = item.item?.createDate ? moment(item.item.createDate).format("YYYY-MM-DD") : "";
              additionalInfo = ` / ${date}`;
            }
            break;
          case Sections.CASE:
            additionalInfo = ` / ${item.item?.date}`;
            break;
          default:
            break;
        }
        return {
          ...item,
          title: `${item.item?.number || item.item?.name || item.item?.id}${additionalInfo}`,
          sectionShort: getSectionsName(item?.section as Sections)?.[0] ?? "",
          section: getSectionsName(item?.section as Sections),
          href: item.href ?? "",
          id: item.id ?? "",
        };
      });
    },
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    openHistoryItem(item: item) {
      this.$router.push({ path: item.href, query: { historyId: item.id } });
    },
  },
});
