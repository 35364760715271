export const tabsTypes = {
  roles: {
    title: "Роли",
    url: "roles",
  },
  users: {
    title: "Пользователи",
    url: "users",
  },
};
