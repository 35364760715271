export const convertItemFromObjToString = (item: Record<string, unknown>): string => {
  try {
    return JSON.stringify(item);
  } catch (e) {
    return "";
  }
};

export const convertItemFromStringToObj = (item: string): Record<string, unknown> => {
  try {
    return JSON.parse(item);
  } catch (e) {
    return {};
  }
};
