export enum Sections {
  EAD = "EAD",
  CASE = "CASE",
  RECEIPT_FROM_OIK = "RECEIPT_FROM_OIK",
  FUND = "FUND",
  STORAGE_CATALOG = "STORAGE_CATALOG",
  ARCHIVE = "ARCHIVE",
  OIK = "OIK",
  DOC_TYPE = "DOC_KIND",
  VERSION = "DOC_KIND_VERSION",
  EVENT = "EVENT",
  CONTAINER_LOG = "CONTAINER_LOG",
  TK_LIST = "TK_LIST",
  SYSTEM_NOTIFICATIONS = "SYSTEM_NOTIFICATIONS",
  USERS_LIST = "USERS_LIST",
  EXTERNAL_SYSTEM_LIST = "EXTERNAL_SYSTEM_LIST",
  RIGHTS_USERS_LIST = "RIGHTS_USERS_LIST",
  AUDIT = "AUDIT",
  AK = "AK",
  FUNDS_REPORTS = "FUNDS_REPORTS",
  GENERATED_RECEIPTS = "GENERATED_REPORTS",
  INVENTORY = "INVENTORY",
  ENDING_EAD_STORAGE_REPORTS = "ENDING_EAD_STORAGE_REPORTS",
  FILESTORAGE = "FILESTORAGE",
  BUG_REPORT = "BUG_REPORT",
  SYNC_LOG_OIK = "SYNC_LOG_OIK",
  ACTS = "ACT",
  CASE_TRANSFER = "CASE_TRANSFER",
  TK_GENERATION = "TK_GENERATION",
  DICTIONARY = "DICTIONARY",
  DICTIONARY_ITEM = "DICTIONARY_ITEM",
  TASKS = "TASKS",
  MONITORING = "MONITORING",
  INVENTORY_PROJECT = "INVENTORY_PROJECT",
  INVENTORY_REPORT = "CASE_DOCUMENT_REPORT",
  NOMENCLATURE_VIEW = "NOMENCLATURE_VIEW",
  MRP = "MRD",

  DOCUMENTS_BOOK_REPORT = "DOCUMENTS_BOOK_REPORT",
  PASSPORT_ARCHIVE_STORE_REPORT_VIEW = "ARCHIVE_PASSPORT_REPORTS",
  PASSPORT_ARCHIVE_REPORT_VIEW = "ARCHIVE_SHORT_PASSPORT",
  FUNDS_LIST = "FUNDS_SHORT_REPORTS",
  FUND_LIST = "FUND_LIST_REPORT",
  INVENTORIES_REGISTER_REPORT_VIEW = "INVENTORY_EAD_REPORT",
}

export interface ISection {
  id: string;
  username: string;
  section: string;
  columnStates: string[];
}

export const getSectionsName = (section: Sections): string => {
  switch (section) {
    case Sections.EAD:
      return "ЭАД";
    case Sections.INVENTORY_PROJECT:
      return "Проект описи";
    case Sections.INVENTORY:
      return "Опись";
    case Sections.CASE:
      return "Дело";
    case Sections.FUND:
      return "Фонд";
    case Sections.AK:
      return "АК";
    case Sections.ARCHIVE:
      return "Архив";
    case Sections.OIK:
      return "ОИК";
    case Sections.EVENT:
      return "Событие";
    case Sections.CONTAINER_LOG:
      return "ТК";
    case Sections.TK_LIST:
      return "ТК";
    case Sections.SYSTEM_NOTIFICATIONS:
      return "Уведомление";
    case Sections.USERS_LIST:
      return "Пользователь";
    case Sections.RIGHTS_USERS_LIST:
      return "Пользователь";
    case Sections.EXTERNAL_SYSTEM_LIST:
      return "Пользователь";
    case Sections.AUDIT:
      return "ИБ";
    case Sections.BUG_REPORT:
      return "Сообщение об ошибке";
    case Sections.SYNC_LOG_OIK:
      return "Обновление справочников";
    case Sections.ACTS:
      return "Акты передачи дел";
    case Sections.CASE_TRANSFER:
      return "График передачи дел";
    case Sections.DOC_TYPE:
      return "Вид документа";
    case Sections.FILESTORAGE:
      return "ХД ЦХЭД";
    case Sections.MRP:
      return "МЧД";
    case Sections.VERSION:
      return "Версия";
    default:
      return "";
  }
};
