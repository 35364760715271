import { Module } from "vuex";
import { INotification } from "@monorepo/app/src/types/INotification";
import store from "@monorepo/app/src/store";
import { IAuthState } from "@monorepo/authorization/src/store/modules/auth";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { getQuery } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import axios from "axios";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { logger } from "html2canvas/dist/types/core/__mocks__/logger";

export type IRootState = Record<string, never>;

interface IAppState {
  notifications: INotification[];
  isPashalka: boolean;
  isExportFileLoading: boolean;
  isOpenProfile: boolean;
  isLoadingAk: string[];
}

const fetchPlus = (url: string, options = { headers: { Authorization: "" } }, retries: number): Promise<Response> =>
  fetch(url, options).then((res: Response) => {
    if (res.ok) {
      return res;
    }
    if (retries > 0 && res?.status === 401) {
      return store
        .dispatch("auth/refreshToken")
        .then((token: string) => {
          return fetchPlus(url, { ...options, headers: { ...(options.headers || {}), Authorization: "Bearer " + token } }, retries - 1);
        })
        .catch(async () => {
          await store.dispatch("logout");
          window.location.href = getFullPath("/login");
          return res;
        });
    }

    return res;
  });

const getFileName = (disposition: string) => {
  let filename = "";
  if (disposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
      if (filename.startsWith("UTF-8")) {
        filename = filename.replace("UTF-8", "");
        filename = decodeURIComponent(filename);
      }
    }
  }
  return filename || "file";
};

export const app: Module<IAppState, IRootState> = {
  namespaced: true,
  state: (): IAppState => ({
    notifications: [],
    isExportFileLoading: false,
    isPashalka: false,
    isOpenProfile: false,
    isLoadingAk: [],
  }),
  mutations: {
    setNotifications(state: IAppState, payload: INotification[]) {
      const isEnabledSetting = (this.state as any).auth.user?.settings?.NOTIFICATION_ENABLED;
      if (isEnabledSetting) {
        const result = state.notifications.concat(payload);
        state.notifications = result.length > 10 ? result.slice(-10) : result;
      }
    },
    setIsExportFileLoading(state: IAppState, payload: boolean) {
      state.isExportFileLoading = payload;
    },
    setIsLoadingAk(state: IAppState, akId: string) {
      state.isLoadingAk = state.isLoadingAk.concat(akId);
    },
    deleteIsLoadingAk(state: IAppState, akId: string) {
      state.isLoadingAk = state.isLoadingAk.filter((item) => item !== akId);
    },
    deleteNotification(state: IAppState, id: string) {
      state.notifications = state.notifications.filter((item) => item.guid !== id);
    },
    setIsPashalka(state: IAppState, isPashalka: boolean) {
      state.isPashalka = isPashalka;
    },
    setIsOpenProfile(state: IAppState, isOpenProfile: boolean) {
      state.isOpenProfile = isOpenProfile;
    },
  },
  actions: {
    openNewWindow(info, payload: string | { url: string; type?: "POST" }) {
      const url = typeof payload === "string" ? payload : payload.url;
      const type = typeof payload === "string" ? null : payload.type;
      const token = (store?.state?.auth as IAuthState)?.user?.details?.token;

      if (!token) {
        showNotification("Ошибка открытия файла. Требуется авторизоваться в системе повторно");
      }

      let akId = "";
      if (url.startsWith(QUERY_PATH.GET_HDUO_DOWNLOAD)) {
        akId = url.indexOf("id=") !== -1 ? url.split("id=")[1] : "";
      }
      if (akId) {
        info.commit("setIsLoadingAk", akId);
      }
      info.commit("setIsExportFileLoading", true);
      (type
        ? fetch(url, {
            method: type,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        : fetchPlus(
            url,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
            1
          )
      ) // FETCH BLOB FROM IT
        .then(async (response) => {
          if (!response.ok) {
            throw new Error((await response.json())?.message || "Ошибка открытия файла.");
          }
          const blob = await response.blob();
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = window.URL.createObjectURL(blob);
          a.download = getFileName(response.headers.get("Content-Disposition") || "");
          a.target = "_blank";
          a.dispatchEvent(new MouseEvent("click", { bubbles: true, cancelable: true, view: window }));
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          if (akId) {
            info.commit("deleteIsLoadingAk", akId);
          }
        })
        .catch((err) => {
          showNotification(err.message);
          if (akId) {
            info.commit("deleteIsLoadingAk", akId);
          }
        })
        .finally(() => {
          info.commit("setIsExportFileLoading", false);
        });
    },
    async sendChangePasswordEmail(info, email: string) {
      try {
        await axios.post(QUERY_PATH.AUTHENTICATION_SEND_EMAIL_RESET_PASSWORD, {
          email,
        });
        showNotification("Ссылка для сброса пароля отправлена вам на электронную почту", NOTIFICATION_STATUS.SUCCESS);
        return true;
      } catch (e) {
        showNotification("Ошибка сброса пароля. Обратитесь в техническую поддержку.");
        return false;
      }
    },
    async getUserSettings() {
      try {
        const { data } = await axios.get(QUERY_PATH.GET_AVAILABLE_USER_SETTINGS);
        return data?.data;
      } catch (e) {
        return [];
      }
    },
    async getUserSettingsValues(info) {
      try {
        const { data } = await axios.get(
          `${QUERY_PATH.GET_AVAILABLE_USER_SETTINGS_VALUES}/${(info.rootState.auth as IAuthState).user?.details?.userId}`
        );
        return data?.data;
      } catch (e) {
        return [];
      }
    },
    async saveUserSettingsValues(info, value: { values: { codeSetting: string; value: string } }) {
      try {
        await axios.post(`${QUERY_PATH.GET_AVAILABLE_USER_SETTINGS_VALUES}/${(info.rootState.auth as IAuthState).user?.details?.userId}`, value);
        return true;
      } catch (e) {
        return false;
      }
    },
    async checkOldPassword(info, password: string) {
      try {
        const { data } = await axios.post(QUERY_PATH.AUTHENTICATION_CHECK_OLD_PASSWORD, {
          password,
        });
        return data?.data;
      } catch (e) {
        return false;
      }
    },
    async saveNewPassword(info, { password, hash }) {
      try {
        await axios[hash ? "post" : "put"](
          hash ? `${QUERY_PATH.AUTHENTICATION_SAVE_NEW_PASSWORD}/${hash}` : QUERY_PATH.AUTHENTICATION_SAVE_NEW_PASSWORD,
          {
            password,
          },
          hash
            ? {
                headers: { Authorization: "" },
              }
            : {}
        );
        return true;
      } catch (e) {
        return false;
      }
    },
    async getNotificationSettings(info, payload: string) {
      const { data } = await getQuery(`${QUERY_PATH.GET_SETTINGS}/${payload}`);
      return data || null;
    },
  },
  getters: {
    notifications: (state: IAppState) => state.notifications ?? [],
    isPashalka: (state: IAppState) => state.isPashalka ?? false,
    isExportFileLoading: (state: IAppState) => state.isExportFileLoading ?? false,
    isOpenProfile: (state: IAppState) => state.isOpenProfile ?? false,
    isLoadingAk: (state: IAppState) => state.isLoadingAk ?? [],
  },
};
