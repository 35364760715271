import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import { auth } from "@monorepo/authorization/src/store/modules/auth";
import { app } from "@monorepo/app/src/store/modules/app";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { IBussinessProcesses } from "@monorepo/monitoring/src/store/modules/monitoringBussinessProcessesView";

export type IRootState = Record<string, never>;

Vue.use(Vuex);

type auth = {
  user?: {
    details?: {
      refreshToken: string;
    };
  };
};

export default new Vuex.Store({
  modules: {
    auth,
    app,
  },
  actions: {
    async logout({ commit, rootState }) {
      try {
        const refreshToken = (rootState?.auth as auth)?.user?.details?.refreshToken || "";
        await axios.delete(getFullPath(QUERY_PATH.AUTHENTICATION_LOGOUT), {
          params: {
            refreshToken,
          },
        });
        commit("auth/resetState");
      } catch (e) {
        console.error("Ошибка logout");
        commit("auth/resetState");
      } finally {
        localStorage.setItem("link", `${location.pathname}${location.search}` as string);
      }
    },
    async getServerVersion(): Promise<IBussinessProcesses[]> {
      try {
        const { data } = await axios.get(getFullPath(QUERY_PATH.GET_SERVER_VERSION));
        return data.data;
      } catch (e) {
        return [];
      }
    },
  },
  plugins: [
    createPersistedState({
      paths: ["auth.user", "auth.isAuth"],
    }),
  ],
});
