import { defineAsyncComponent } from "@vue/composition-api";
import { viewUniqKey as eadViewUniqKey, newViewUniqKey } from "@monorepo/utils/src/variables/projectsData/eadView/viewTitle";
import { viewUniqKey as inventoryViewUniqKey } from "@monorepo/utils/src/variables/projectsData/inventoryView/viewTitle";
import { viewUniqKey as TKListViewUniqKey } from "@monorepo/utils/src/variables/projectsData/TKListView/viewTitle";
import { viewUniqKey as akViewUniqKey } from "@monorepo/utils/src/variables/projectsData/akView/viewTitle";
import { viewUniqKey as ERCProtocolsViewUniqKey } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/viewTitle";
import { viewUniqKey as actsViewUniqKey } from "@monorepo/utils/src/variables/projectsData/actsView/viewTitle";
import { viewUniqKey as handoverViewUniqKey } from "@monorepo/utils/src/variables/projectsData/handoverView/viewTitle";
import { viewUniqKey as mrpViewUniqKey } from "@monorepo/utils/src/variables/projectsData/mrpView/viewTitle";

const InventoryView = defineAsyncComponent(
  () => import(/* webpackChunkName: "inventorySkedView" */ "@monorepo/inventory/src/views/InventoryView/InventoryView.vue")
);
const EadView = defineAsyncComponent(() => import(/* webpackChunkName: "inventoryEadView" */ "@monorepo/inventory/src/views/EadView/EadView.vue"));

const TKListView = defineAsyncComponent(() => import(/* webpackChunkName: "TKListView" */ "@monorepo/inventory/src/views/TKListView/TKListView.vue"));
const AKListView = defineAsyncComponent(() => import(/* webpackChunkName: "AKListView" */ "@monorepo/inventory/src/views/AKView/AKView.vue"));
const ERCProtocolsView = defineAsyncComponent(
  () => import(/* webpackChunkName: "InventoryERCProtocolsView" */ "@monorepo/inventory/src/views/ERCProtocolsView/ERCProtocolsView.vue")
);
const ActsListView = defineAsyncComponent(() => import(/* webpackChunkName: "ActsListView" */ "@monorepo/inventory/src/views/ActsView/ActsView.vue"));
const HandoverListView = defineAsyncComponent(
  () => import(/* webpackChunkName: "HandoverListView" */ "@monorepo/inventory/src/views/HandoverView/HandoverView.vue")
);
const MRPListView = defineAsyncComponent(() => import(/* webpackChunkName: "MRPListView" */ "@monorepo/inventory/src/views/MRPView/MRPView.vue"));

export const commonRoutes = [
  {
    path: "/inventory/case-notes-list",
    component: ERCProtocolsView,
    meta: { requiresAuth: true, key: ERCProtocolsViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/inventory/case",
    component: InventoryView,
    meta: { requiresAuth: true, key: inventoryViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/inventory/ead",
    component: EadView,
    meta: { requiresAuth: true, key: eadViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/inventory/tk",
    component: TKListView,
    meta: { requiresAuth: true, key: TKListViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/inventory/ak",
    component: AKListView,
    meta: { requiresAuth: true, key: akViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/inventory/mrp",
    component: MRPListView,
    meta: { requiresAuth: true, key: mrpViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/inventory/acts",
    component: ActsListView,
    meta: { requiresAuth: true, key: actsViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/inventory/case-transfer-years",
    component: HandoverListView,
    meta: { requiresAuth: true, key: handoverViewUniqKey, isNeedNavigationPanel: true },
  },
];
