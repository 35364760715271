import axios from "axios";
import store from "../store";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";

let isRefreshing = false;
// doesn't matter type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let failedQueue: { resolve: (e: any) => void; reject: (e: any) => void }[] = [];

// doesn't matter type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const pathNotToResend = [QUERY_PATH.AUTHENTICATION_LOGIN, QUERY_PATH.AUTHENTICATION_REFRESH_TOKEN];

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalRequest = err.config;
    if (
      err?.response?.status === 401 &&
      !pathNotToResend.some((path: string) => err?.request?.responseURL.includes(path)) &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve) {
        store
          .dispatch("auth/refreshToken")
          .then((token: string) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            processQueue(null, token);
            resolve(axios(originalRequest));
          })
          .catch(async (err) => {
            processQueue(err, null);
            await store.dispatch("logout");
            window.location.href = getFullPath("/login");
            return;
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    if (err?.response?.status === 403) {
      showNotification("Недостаточно прав для выполнения операции. Обратитесь к Администратору.");
      return Promise.reject({ noBaseError: true });
    }

    return Promise.reject(err);
  }
);
