export enum NOTIFICATION_STATUS {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  INFO = "INFO",
}

export interface INotification {
  status: NOTIFICATION_STATUS;
  message: string;
}
