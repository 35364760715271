const {
  VUE_APP_WORKER_SERVICE_PATH,
  VUE_APP_AUTHENTICATION_SERVICE_PATH,
  VUE_APP_CLIENT_VERSION,
  VUE_APP_DOCUMENT_REGISTRATION_SERVICE_PATH,
  VUE_APP_DICTIONARY_SERVICE_PATH,
  VUE_APP_REPORT_SERVICE_PATH,
  VUE_APP_FILE_STORAGE_SERVICE_PATH,
  VUE_APP_NOTIFICATION_SERVICE_PATH,
  VUE_APP_HDUO_SERVICE_PATH,
  VUE_APP_ADMINISTRATION_SERVICE_PATH,
  VUE_APP_INFORMATION_RETRIEVAL_SERVICE_PATH,
  VUE_APP_AUDIT_SERVICE_PATH,
  VUE_APP_CRYPTO_SERVICE_PATH,
  VUE_APP_INTEGRATION_TEST_SERVICE_PATH,
  VUE_APP_EXPERT_COMISSION_SERVICE_PATH,
  BASE_URL,
} = process.env;

export const environmentVariables = {
  WORKER_SERVICE_PATH: VUE_APP_WORKER_SERVICE_PATH || "",
  DOCUMENT_REGISTRATION_SERVICE_PATH: VUE_APP_DOCUMENT_REGISTRATION_SERVICE_PATH || "",
  DICTIONARY_SERVICE_PATH: VUE_APP_DICTIONARY_SERVICE_PATH || "",
  AUTHENTICATION_SERVICE_PATH: VUE_APP_AUTHENTICATION_SERVICE_PATH || "",
  REPORT_SERVICE_PATH: VUE_APP_REPORT_SERVICE_PATH || "",
  FILE_STORAGE_SERVICE_PATH: VUE_APP_FILE_STORAGE_SERVICE_PATH || "",
  NOTIFICATION_SERVICE_PATH: VUE_APP_NOTIFICATION_SERVICE_PATH || "",
  BASE_URL: BASE_URL || "",
  VUE_APP_CLIENT_VERSION: VUE_APP_CLIENT_VERSION || "",
  VUE_APP_HDUO_SERVICE_PATH: VUE_APP_HDUO_SERVICE_PATH || "",
  VUE_APP_ADMINISTRATION_SERVICE_PATH: VUE_APP_ADMINISTRATION_SERVICE_PATH || "",
  VUE_APP_INFORMATION_RETRIEVAL_SERVICE_PATH: VUE_APP_INFORMATION_RETRIEVAL_SERVICE_PATH || "",
  VUE_APP_AUDIT_SERVICE_PATH: VUE_APP_AUDIT_SERVICE_PATH || "",
  VUE_APP_CRYPTO_SERVICE_PATH: VUE_APP_CRYPTO_SERVICE_PATH || "",
  INTEGRATION_TEST_SERVICE_PATH: VUE_APP_INTEGRATION_TEST_SERVICE_PATH || "",
  EXPERT_COMISSION_SERVICE_PATH: VUE_APP_EXPERT_COMISSION_SERVICE_PATH || "",
};
