







































































































import { defineComponent } from "@vue/composition-api";
import { ISubNav, navigationItems } from "../constants/navigationItems";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { mapActions, mapGetters } from "vuex";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { getSectionsName } from "@monorepo/utils/src/types/cellsSections";
import moment from "moment";
import FastHistoryItems from "./FastHistoryItems.vue";

export default defineComponent({
  name: "Navigation",
  components: {
    FastHistoryItems,
  },
  data() {
    return {
      isShowSubNavigation: false,
      isShowHistory: false,
      openSubNavId: "",
      timerId: 0,
      navigationItems,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "userSettings"]),
    historyLength(): number {
      return (this.user?.history || []).length;
    },
    selectedNav(): Partial<typeof navigationItems[0]> {
      if (this.isShowHistory) {
        return {
          title: "История просмотров",
          subNav: [...(this.user?.history || [])].map((item) => {
            return {
              ...item,
              title: `${getSectionsName(item.section)}: ${item.item.number || item.item.name || item.item.id || ""}`,
              tooltip: `${getSectionsName(item.section)} - ${item.item.number || item.item.name || item.item.id || ""}`,
            };
          }),
        };
      }

      const element = this.navigationItems.find((element) => element.id === this.openSubNavId);
      return element ?? {};
    },
    isShowMenu(): (id: string) => boolean {
      return (id: string) => {
        const subNav = this.navigationItems.find((element) => element.id === id)?.subNav || [];
        const subNavsResult = this.getResultSubNavs(subNav);
        return !!subNavsResult?.length;
      };
    },
    selectedSubNav(): ISubNav[] {
      return this.getResultSubNavs(this.selectedNav?.subNav ?? []);
    },
    historyItems(): { [key: string]: ISubNav[] } {
      return (this.selectedNav?.subNav ?? []).reduce((result: { [key: string]: ISubNav[] }, element: ISubNav) => {
        let resultDate: string;
        if (moment(element.createDate).isSame(moment(), "day")) {
          resultDate = "Сегодня";
        } else if (moment(element.createDate).isSame(moment().add(-1, "day"), "day")) {
          resultDate = "Вчера";
        } else {
          resultDate = moment(element.createDate?.toString()).format("YYYY-MM-DD") || "";
        }
        result[resultDate] = [...(result[resultDate] || []), element];
        return result;
      }, {});
    },
  },
  methods: {
    ...mapActions("auth", ["getUserHistory"]),
    ...mapActions("auth", ["getCommonUserSettingsValues"]),
    getResultSubNavs(subNav: ISubNav[]) {
      const authorities: { authority: string }[] = this.user?.authorities || [];
      return subNav.filter((nav) => {
        return nav.rights ? (authorities || []).some((auth) => nav.rights?.includes(auth.authority as authorities)) : true;
      });
    },
    openModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    openEducationPanelModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "EducationPanelModal"));
    },
    openHistory() {
      this.openSubnav("history");
      this.isShowHistory = true;
    },
    closeHistory() {
      this.timerId = setTimeout(() => {
        this.closeSubnav();
        this.isShowHistory = false;
      }, 500);
    },
    openSubnav(id?: string) {
      clearTimeout(this.timerId);
      if (!id) {
        return;
      }
      this.isShowSubNavigation = true;
      if (id) {
        this.isShowHistory = false;
        this.openSubNavId = id;
      }
    },
    closeSubnav() {
      this.isShowSubNavigation = false;
      this.isShowHistory = false;
      this.openSubNavId = "";
    },
    closeSubnavWithTimer() {
      this.timerId = setTimeout(this.closeSubnav, 500);
    },
    openNavigation(route: typeof navigationItems[0] | ISubNav) {
      if (this.isShowHistory) {
        this.$router.push({ path: route.href, query: { historyId: route.id } });
        return;
      }

      if (route.isMock) {
        console.log("mock");
      } else if (route.href && this.$route.path === route.href) {
        const url = new URL(route.href, window.location.origin);
        window.location.href = url.href;
      } else if (route.href && this.$route.path !== route.href) {
        this.$router.push(route.href);

        this.closeSubnav();
        clearTimeout(this.timerId);
      }
    },
  },
  mounted() {
    this.getUserHistory();
    this.getCommonUserSettingsValues();
  },
});
