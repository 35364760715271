import { IActionType } from "../types/action";
import { INotification, NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

export const formNotificationPayload = (name: string, status: NOTIFICATION_STATUS, message: string): IActionType<INotification> => {
  return {
    name,
    payload: {
      status,
      message,
    },
  };
};
