import { IActionType } from "../types/action";
import { IModalEventPayload } from "../types/modalPayload";
import { MODAL_EVENT_BUS_ACTIONS } from "../events/modal";

export const formModalPayload = (isOpen: boolean, name?: string, props?: Record<string, unknown>): IActionType<IModalEventPayload> => {
  return {
    name: MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
    payload: {
      name,
      isOpen,
      props,
    },
  };
};
