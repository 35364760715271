








































import { mapActions, mapGetters } from "vuex";
import { defineComponent } from "@vue/composition-api";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import Notification from "@monorepo/app/src/components/Notification.vue";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { INotification } from "@monorepo/app/src/types/INotification";
import { cloneDeep } from "lodash";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

export default defineComponent({
  name: "NotificationsList",
  components: {
    Notification,
  },
  data() {
    return {
      isShowNotification: false,
      notificationsLocal: [] as INotification[],
      isNeedHide: false,
      settings: [] as {
        name: string;
        code: string;
        type: string;
        description: string;
      }[],
    };
  },
  computed: {
    ...mapGetters("auth", ["userSettings"]),
    ...mapGetters("app", ["notifications"]),
    isShowAnimation(): boolean {
      return this.userSettings["ANIMATION"];
    },
    isHideNotifications(): boolean {
      return !this.userSettings["NOTIFICATION_ENABLED"];
    },
    icon(): string {
      return `${environmentVariables.BASE_URL}assets/images/navigation/bell.gif`;
    },
  },
  methods: {
    ...mapActions("app", ["getUserSettings", "saveUserSettingsValues"]),
    ...mapActions("auth", ["getCommonUserSettingsValues"]),
    openNotification(notification: INotification) {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "NotificationModal", { notification, cbOnDelete: this.deleteNotification })
      );
      this.hideNotificationsList();
    },
    deleteNotification(id: string) {
      this.$store.commit("app/deleteNotification", id);
      this.notificationsLocal = cloneDeep(this.notifications);
    },
    hideNotificationsList() {
      this.isShowNotification = false;
    },
    async showNotifications() {
      if (!this.settings?.length || !Object.entries(this.userSettings).length) {
        return;
      }
      const settingsValues = this.settings.map((setting) => ({
        codeSetting: setting.code,
        value: setting.code === "NOTIFICATION_ENABLED" ? !this.userSettings[setting.code] : !!this.userSettings[setting.code],
      }));
      const isSave = await this.saveUserSettingsValues({ values: settingsValues });
      showNotification(
        !isSave ? "Ошибка сохранения настроек" : "Настройки успешно сохранены",
        !isSave ? NOTIFICATION_STATUS.ERROR : NOTIFICATION_STATUS.SUCCESS
      );
      await this.getCommonUserSettingsValues();
    },
    updateNotifications() {
      if (!this.$store.state.auth.user?.settings?.NOTIFICATION_ENABLED) {
        return;
      }
      this.isNeedHide = true;
      this.notificationsLocal = cloneDeep(this.notifications);
      this.isShowNotification = true;
    },
  },
  async mounted() {
    this.settings = await this.getUserSettings();
  },
  beforeMount() {
    eventBus.$on("newNotification", this.updateNotifications);
  },
  beforeDestroy() {
    eventBus.$off("newNotification", this.updateNotifications);
  },
});
