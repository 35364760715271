export enum authorities {
  AK_LIST = "AK_LIST",
  AK_EXPORT = "AK_EXPORT",
  AK_RESIGN = "AK_RESIGN",
  AK_DOWNLOAD = "AK_DOWNLOAD",
  MONITORING_LIST = "MONITORING_LIST",
  OIK_LIST = "OIK_LIST",
  OIK_CREATE = "OIK_CREATE",
  OIK_MODIFY = "OIK_MODIFY",
  OIK_DELETE = "OIK_DELETE",
  OIK_EXPORT = "OIK_EXPORT",
  OIK_LOG_UPDATE_MODIFY = "OIK_LOG_UPDATE_MODIFY",
  ADMIN = "ADMIN",
  JOB_STARTER_CREATE = "JOB_STARTER_CREATE",
  OIK_LOG_MODIFY = "OIK_LOG_MODIFY",
  OIK_LOG_LIST = "OIK_LOG_LIST",
  OIK_LOG_EXPORT = "OIK_LOG_EXPORT",
  ARCHIVE_LIST = "ARCHIVE_LIST",
  ARCHIVE_CREATE = "ARCHIVE_CREATE",
  ARCHIVE_MODIFY = "ARCHIVE_MODIFY",
  ARCHIVE_DELETE = "ARCHIVE_DELETE",
  ARCHIVE_EXPORT = "ARCHIVE_EXPORT",
  ARCHIVE_CONCAT = "ARCHIVE_MERGE",
  FUND_LIST = "FUND_LIST",
  FUND_CREATE = "FUND_CREATE",
  FUND_MODIFY = "FUND_MODIFY",
  FUND_DELETE = "FUND_DELETE",
  FUND_EXPORT = "FUND_EXPORT",
  FUND_CONCAT = "FUND_MERGE",
  CASE_LIST = "CASE_LIST",
  CASE_MODIFY = "CASE_MODIFY",
  CASE_DELETE = "CASE_DELETE",
  CASE_EXPORT = "CASE_EXPORT",
  TK_JOURNAL_LIST = "TK_JOURNAL_LIST",
  TK_JOURNAL_EXPORT = "TK_JOURNAL_EXPORT",
  EVENT_LOG_LIST = "EVENT_LOG_LIST",
  EVENT_LOG_EXPORT = "EVENT_LOG_EXPORT",
  INVENTORY_LIST = "INVENTORY_LIST",
  INVENTORY_EXPORT = "INVENTORY_EXPORT",
  INFORMATION_SECURITY_JOURNAL_LIST = "IS_EVENT_LOG_LIST",
  INFORMATION_SECURITY_JOURNAL_EXPORT = "IS_EVENT_LOG_EXPORT",
  EAD_LIST = "EAD_LIST",
  EAD_EXPORT = "EAD_EXPORT",
  REPORT_LIST = "REPORT_LIST",
  REPORT_EXPORT = "REPORT_EXPORT",
  CASE_ACT_LIST = "CASE_ACT_LIST",
  CASE_ACT_EXPORT = "CASE_ACT_EXPORT",
  CASE_ACT_CREATE = "CASE_ACT_CREATE",
  DATA_STORAGE_LIST = "DATA_STORAGE_LIST",
  DATA_STORAGE_EXPORT = "DATA_STORAGE_EXPORT",
  DATA_STORAGE_DELETE = "DATA_STORAGE_DELETE",
  NOTIFICATION_LIST = "NOTIFICATION_LIST",
  NOTIFICATION_CREATE = "NOTIFICATION_CREATE",
  NOTIFICATION_MODIFY = "NOTIFICATION_MODIFY",
  NOTIFICATION_DELETE = "NOTIFICATION_DELETE",
  NOTIFICATION_EXPORT = "NOTIFICATION_EXPORT",
  ROLE_LIST = "ROLE_LIST",
  ROLE_CREATE = "ROLE_CREATE",
  ROLE_MODIFY = "ROLE_MODIFY",
  ROLE_DELETE = "ROLE_DELETE",
  ROLE_EXPORT = "ROLE_EXPORT",
  USER_CREATE = "USER_CREATE",
  USER_MODIFY = "USER_MODIFY",
  USER_DELETE = "USER_DELETE",
  USER_ACTIVATE = "USER_ACTIVATE",
  USER_DEACTIVATE = "USER_DEACTIVATE",
  USER_LIST = "USER_LIST",
  USER_EXPORT = "USER_EXPORT",
  SERVICE_DIRECTORIES_LIST = "SERVICE_DICTIONARY_LIST",
  SERVICE_DIRECTORIES_CREATE = "SERVICE_DICTIONARY_CREATE",
  SERVICE_DIRECTORIES_MODIFY = "SERVICE_DICTIONARY_MODIFY",
  SERVICE_DIRECTORIES_DELETE = "SERVICE_DICTIONARY_DELETE",
  SERVICE_DIRECTORIES_EXPORT = "SERVICE_DICTIONARY_EXPORT",
  REPORT_GENERATED_LIST = "REPORT_GENERATED_LIST",
  REPORT_GENERATED_DELETE = "REPORT_GENERATED_DELETE",
  REPORT_GENERATED_EXPORT = "REPORT_GENERATED_EXPORT",
  SCHEDULED_REPORT_CREATE = "SCHEDULED_REPORT_CREATE",
  SCHEDULED_REPORT_MODIFY = "SCHEDULED_REPORT_MODIFY",
  SCHEDULED_REPORT_LIST = "SCHEDULED_REPORT_LIST",
  SCHEDULED_REPORT_DELETE = "SCHEDULED_REPORT_DELETE",
  SETUP_CWS = "SETUP_CWS",
  SETUP_DS = "SETUP_DS",
  SETUP_RS = "SETUP_RS",
  SETUP_DRS = "SETUP_DRS",
  SETUP_IRS = "SETUP_IRS",
  SETUP_MAA = "SETUP_MAA",
  SETUP_HDUOIS = "SETUP_HDUOIS",
  SETUP_HD_STORAGE_CHED = "SETUP_HD_STORAGE_CHED",
  SETUP_IB = "SETUP_IB",
  SETUP_SYSTEM = "SETUP_SYSTEM",
  SETTINGS_LIST = "SETUP_LIST",
  OIK_CASE_TRANSFER_LIST = "OIK_CASE_TRANSFER_LIST",
  OIK_CASE_TRANSFER_MODIFY = "OIK_CASE_TRANSFER_MODIFY",
  OIK_CASE_TRANSFER_EXPORT = "OIK_CASE_TRANSFER_EXPORT",
  TECH_INFO = "TECH_INFO",
  TK_DOCUMENTS_LIST = "TK_DOCUMENTS_LIST",
  TK_DOCUMENTS_EXPORT = "TK_DOCUMENTS_EXPORT",
  TK_DICTIONARIES_LIST = "TK_DICTIONARIES_LIST",
  TK_DICTIONARIES_EXPORT = "TK_DICTIONARIES_EXPORT",
  TK_OTHER_LIST = "TK_OTHER_LIST",
  TK_OTHER_EXPORT = "TK_OTHER_EXPORT",
  TK_GENERATION = "TK_GENERATION",
  EXPERT_COMMISSION_LIST = "EXPERT_COMMISSION_LIST",
  ERROR_MSG_LIST = "ERROR_MSG_LIST",
  ERROR_MSG_EXPORT = "ERROR_MSG_EXPORT",
  ACT_OF_ACCEPTANCE_LIST = "ACT_OF_ACCEPTANCE_LIST",
  ACT_OF_ACCEPTANCE_EXPORT = "ACT_OF_ACCEPTANCE_EXPORT",
  EXPERT_COMMISSION_CREATION = "EXPERT_COMMISSION_CREATION_MODIFY",
  EXPERT_COMMISSION_EXPORT = "EXPERT_COMMISSION_EXPORT",
  EXPERT_COMMISSION_APPROVER = "EXPERT_COMMISSION_APPROVING_MODIFY",
  EXPERT_COMMISSION_COORDINATOR = "EXPERT_COMMISSION_COORDINATING_MODIFY",
  ITRA_LIST = "ITRA_LIST",
  ITRA_EXPORT = "ITRA_EXPORT",
  ITRA_CREATE = "ITRA_CREATE",

  DOC_KIND_LIST = "DOC_KIND_LIST",
  DOC_KIND_CREATE = "DOC_KIND_CREATE",
  DOC_KIND_MODIFY = "DOC_KIND_MODIFY",
  DOC_KIND_DELETE = "DOC_KIND_DELETE",

  NOMENCLATURE_LIST = "NOMENCLATURE_LIST",

  GAS_NSI_EXPORT = "GAS_NSI_EXPORT",
}
