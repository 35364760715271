import { defineAsyncComponent } from "@vue/composition-api";
import { viewUniqKey as oikViewUniqKey } from "@monorepo/utils/src/variables/projectsData/oikView/viewTitle";
import { viewUniqKey as archiveViewUniqKey } from "@monorepo/utils/src/variables/projectsData/archiveView/viewTitle";
import { viewUniqKey as fundViewUniqKey } from "@monorepo/utils/src/variables/projectsData/fundView/viewTitle";
import { viewUniqKey as docTypeViewUniqKey } from "@monorepo/utils/src/variables/projectsData/docTypeView/viewTitle";
import { viewUniqKey as versionCatalogViewUniqKey } from "@monorepo/utils/src/variables/projectsData/versionCatalogView/viewTitle";
import { viewUniqKey as serviceDirectoriesViewUniqKey } from "@monorepo/utils/src/variables/projectsData/serviceDirectories/viewTitle";
import { viewUniqKey as storageViewUniqKey } from "@monorepo/utils/src/variables/projectsData/storageView/viewTitle";
import { viewUniqKey as storageTermsViewUniqKey } from "@monorepo/utils/src/variables/projectsData/storageTerms/viewTitle";

const OikView = defineAsyncComponent(() => import(/* webpackChunkName: "catalogOikSkedView" */ "@monorepo/catalog/src/views/OikView/OikView.vue"));

const ArchiveView = defineAsyncComponent(
  () => import(/* webpackChunkName: "catalogArchiveSkedView" */ "@monorepo/catalog/src/views/ArchiveView/ArchiveView.vue")
);
const FundView = defineAsyncComponent(
  () => import(/* webpackChunkName: "catalogFundSkedView" */ "@monorepo/catalog/src/views/FundView/FundView.vue")
);
const DocTypeView = defineAsyncComponent(
  () => import(/* webpackChunkName: "catalogDocTypeViewView" */ "@monorepo/catalog/src/views/DocTypeView/DocTypeView.vue")
);
const VersionCatalogView = defineAsyncComponent(
  () => import(/* webpackChunkName: "catalogVersionCatalogView" */ "@monorepo/catalog/src/views/VersionCatalogView/VersionCatalogView.vue")
);
const ServiceDirectoriesView = defineAsyncComponent(
  () =>
    import(/* webpackChunkName: "catalogServiceDirectoriesSkedView" */ "@monorepo/catalog/src/views/ServiceDirectories/ServiceDirectoriesView.vue")
);
const StorageView = defineAsyncComponent(
  () => import(/* webpackChunkName: "catalogStorageSkedView" */ "@monorepo/catalog/src/views/StorageView/StorageView.vue")
);
const StorageTermsView = defineAsyncComponent(
  () => import(/* webpackChunkName: "catalogStorageTermsSkedView" */ "@monorepo/catalog/src/views/StorageTerms/StorageTermsView.vue")
);

export const commonRoutes = [
  {
    path: "/dictionaries/oik",
    component: OikView,
    meta: { requiresAuth: true, key: oikViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/dictionaries/archive",
    component: ArchiveView,
    meta: { requiresAuth: true, key: archiveViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/dictionaries/fund",
    component: FundView,
    meta: { requiresAuth: true, key: fundViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/dictionaries/versions",
    component: VersionCatalogView,
    meta: { requiresAuth: true, key: versionCatalogViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/dictionaries/doc-type",
    component: DocTypeView,
    meta: { requiresAuth: true, key: docTypeViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/dictionaries/service-dictionaries",
    component: ServiceDirectoriesView,
    meta: { requiresAuth: true, key: serviceDirectoriesViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/dictionaries/storage",
    component: StorageView,
    meta: { requiresAuth: true, key: storageViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/dictionaries/storage-terms",
    component: StorageTermsView,
    meta: { requiresAuth: true, key: storageTermsViewUniqKey, isNeedNavigationPanel: true },
  },
];
