import { defineAsyncComponent } from "@vue/composition-api";
import { viewUniqKey as receiptsFromOicViewUniqKey } from "@monorepo/utils/src/variables/projectsData/receiptFromOikView/viewTitle";
import { viewUniqKey as generatedReportsViewUniqKey } from "@monorepo/utils/src/variables/projectsData/generatedReportsView/viewTitle";
import { viewUniqKey as fundsReportsViewUniqKey } from "@monorepo/utils/src/variables/projectsData/fundsReportsView/viewTitle";
import { viewUniqKey as receiptDocumentsBookReportUniqKey } from "@monorepo/utils/src/variables/projectsData/receiptDocumentsBookReport/viewTitle";
import { viewUniqKey as fundListReportUniqKey } from "@monorepo/utils/src/variables/projectsData/fundListReport/viewTitle";
import { viewUniqKey as fundsListReportUniqKey } from "@monorepo/utils/src/variables/projectsData/fundsListReport/viewTitle";
import { viewUniqKey as inventoryReportViewUniqKey } from "@monorepo/utils/src/variables/projectsData/inventoryReportView/viewTitle";
import { viewUniqKey as inventoriesRegisterReportUniqKey } from "@monorepo/utils/src/variables/projectsData/inventoriesRegisterReport/viewTitle";
import { viewUniqKey as passportArchiveReportUniqKey } from "@monorepo/utils/src/variables/projectsData/passportArchiveReport/viewTitle";
import { viewUniqKey as passportArchiveStoreReportUniqKey } from "@monorepo/utils/src/variables/projectsData/fundsListReport/viewTitle";

const ReceiptsFromOicView = defineAsyncComponent(
  () => import(/* webpackChunkName: "ReceiptsFromOicViewSkedView" */ "@monorepo/reports/src/views/ReceiptsFromOikView/ReceiptsFromOikView.vue")
);
const GeneratedReportsView = defineAsyncComponent(
  () => import(/* webpackChunkName: "GeneratedReportsSkedView" */ "@monorepo/reports/src/views/GeneratedReportsView/GeneratedReportsView.vue")
);
const FundsReportsView = defineAsyncComponent(
  () => import(/* webpackChunkName: "FundsReportsSkedView" */ "@monorepo/reports/src/views/FundsReportsView/FundsReportsView.vue")
);
const EadStorageReportsView = defineAsyncComponent(
  () => import(/* webpackChunkName: "EadStorageReportsView" */ "@monorepo/reports/src/views/EndStoragePeriodEad/EndStoragePeriodEadView.vue")
);
const InventoryReportView = defineAsyncComponent(
  () => import(/* webpackChunkName: "InventoryReportView" */ "@monorepo/reports/src/views/InventoryReportView/InventoryReportView.vue")
);
const DocumentsBookReportView = defineAsyncComponent(
  () => import(/* webpackChunkName: "DocumentsBookReportView" */ "@monorepo/reports/src/views/DocumentsBookReportView/DocumentsBookReportView.vue")
);
const FundsListView = defineAsyncComponent(
  () => import(/* webpackChunkName: "FundsListView" */ "@monorepo/reports/src/views/FundsListView/FundsListView.vue")
);
const FundListView = defineAsyncComponent(
  () => import(/* webpackChunkName: "FundListView" */ "@monorepo/reports/src/views/FundListView/FundListView.vue")
);
const InventoriesRegisterReportView = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "InventoriesRegisterReportView" */ "@monorepo/reports/src/views/InventoriesRegisterReportView/InventoriesRegisterReportView.vue"
    )
);
const PassportArchiveReportView = defineAsyncComponent(
  () =>
    import(/* webpackChunkName: "PassportArchiveReportView" */ "@monorepo/reports/src/views/PassportArchiveReportView/PassportArchiveReportView.vue")
);
const PassportArchiveStoreReportView = defineAsyncComponent(
  () =>
    import(
      /* webpackChunkName: "PassportArchiveStoreReportView" */ "@monorepo/reports/src/views/PassportArchiveStoreReportView/PassportArchiveStoreReportView.vue"
    )
);

export const commonRoutes = [
  {
    path: "/reports/received-documents",
    component: ReceiptsFromOicView,
    meta: { requiresAuth: true, key: receiptsFromOicViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/reports/funds-reports",
    component: FundsReportsView,
    meta: { requiresAuth: true, key: fundsReportsViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/reports/generated-reports",
    component: GeneratedReportsView,
    meta: { requiresAuth: true, key: generatedReportsViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/reports/ead-storage-reports",
    component: EadStorageReportsView,
    meta: { requiresAuth: true, key: generatedReportsViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/reports/archived-documents",
    component: DocumentsBookReportView,
    meta: { requiresAuth: true, key: receiptDocumentsBookReportUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/reports/funds-list",
    component: FundsListView,
    meta: { requiresAuth: true, key: fundsListReportUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/reports/fund-list",
    component: FundListView,
    meta: { requiresAuth: true, key: fundListReportUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/reports/inventory-report",
    component: InventoryReportView,
    meta: { requiresAuth: true, key: inventoryReportViewUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/reports/inventories-register-report",
    component: InventoriesRegisterReportView,
    meta: { requiresAuth: true, key: inventoriesRegisterReportUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/reports/passport-archive-report",
    component: PassportArchiveReportView,
    meta: { requiresAuth: true, key: passportArchiveReportUniqKey, isNeedNavigationPanel: true },
  },
  {
    path: "/reports/passport-archive-store-report",
    component: PassportArchiveStoreReportView,
    meta: { requiresAuth: true, key: passportArchiveStoreReportUniqKey, isNeedNavigationPanel: true },
  },
];
